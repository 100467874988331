import React from "react"
//import AwesomeSlider from "react-awesome-slider"
//import "react-awesome-slider/dist/styles.css"
import { kebabCase } from "lodash"
import Img from "gatsby-image"
import PortableText from "../components/portableText"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

function CoursesComponent(props) {
  return (
    <section
      key={props._key}
      className="relative items-center justify-center pt-6 pb-12 mx-auto"
    >
      <h2 className="max-w-6xl mx-auto mt-12 mb-2 font-serif text-2xl font-bold text-center text-gray-900 md:px-12 md:text-4xl ">
        {props.title}
      </h2>
      <div className="px-4 py-0 mx-auto text-white sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-6 pb-12 mx-auto mt-6 max-w-7xl md:px-6 lg:grid-cols-3">
          {props.content.map(({ courses, _key }) => (
            <div
              key={_key}
              className="relative overflow-hidden transition-all duration-700 ease-in-out transform bg-gray-100 border border-transparent rounded-lg shadow-xl hover:-translate-y-1 hover:border-gray-800 hover:shadow-2xl "
            >
              <Link
                to={`/cursos/${kebabCase(courses.slug.current)}`}
                className="relative block bg-black"
              >
                <h3 className="absolute bottom-0 z-20 px-8 pb-3 font-serif text-2xl font-bold text-gray-100">
                  {courses.title}
                </h3>
                <Img
                  fluid={courses.image.asset.fluid}
                  className="relative z-0 object-fill w-full h-48 transition-all duration-300 ease-in-out transform opacity-60 hover:opacity-50"
                  width="350"
                  height="192"
                  alt={courses.title}
                  title={courses.title}
                />
              </Link>
              <div className="relative z-30 pb-12 overflow-hidden prose prose-lg text-white ">
                <div className="flex-1 h-full px-8 font-sans text-base leading-relaxed md:line-clamp-5 ">
                  {courses._rawExcerpt && (
                    <PortableText blocks={courses._rawExcerpt} />
                  )}
                </div>
              </div>
              <Link
                to={`/cursos/${kebabCase(courses.slug.current)}`}
                className="absolute bottom-0 left-0 right-0 z-50 px-8 btn"
              >
                <FormattedMessage id="see_more_details" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CoursesComponent
