import React from "react"

function InfoRow(props) {
  return (
    <section
      key={props._key}
      className="relative items-center justify-center pt-12 pb-32 mx-auto "
    >
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="space-y-10 text-center md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          {props.content.map(({ title, description, _key }) => (
            <div key={_key} className="p-6 py-1 prose prose-lg">
              <h3 className="font-serif font-medium leading-6 text-gray-100">
                {title}
              </h3>
              <div className="text-gray-900">{description}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default InfoRow
