import React from "react"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby-plugin-intl"
import { GiLaurels } from "react-icons/gi"

function InfoRow(props) {
  return (
    <section key={props._key} className="relative px-6 pt-24 mx-auto ">
      <div className="hidden w-full max-w-xl mx-auto mb-12 border-b-2 border-gray-500">
        <Img fluid={props.image.asset.fluid} className="relative " />
      </div>
      <div className="flex flex-col items-center justify-center max-w-6xl mx-auto mb-3 text-center">
        <GiLaurels className="mb-6 text-6xl opacity-50" />
        <div className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {props.title}
        </div>
        <div className="max-w-2xl mt-4 text-xl text-gray-900 lg:mx-auto">
          {props.description}
        </div>
        <Link
          to={`/contact/`}
          className="block px-6 py-2 mt-6 font-serif text-base font-bold text-center text-gray-800 no-underline uppercase transition-all duration-500 transform bg-gray-200 hover:bg-gray-800 hover:text-white "
        >
          <FormattedMessage id="contact_us" />
        </Link>
        <hr className="max-w-xs my-6 border-b-4 border-gray-100 opacity-25 w-7" />
      </div>
    </section>
  )
}

export default InfoRow
